import {
    ChatBubbleLeftRightIcon,
    SparklesIcon,
    TableCellsIcon,
} from '@heroicons/vue/24/outline'

export interface Solution {
    name: string
    subtitle: string
    description: string
    href: string | null
    icon: any
    imageUrl: string
}

export const solutions: Solution[] = [
    {
        name: 'Bill Boards',
        subtitle: 'Insights at a glance for you and your membership',
        description:
            'Visual bill tracking for you and your stakeholders to embed in your website and on social media.',
        href: '/solutions/bill-boards',
        icon: TableCellsIcon,
        imageUrl: '/bill-boards-mac.png',
    },
    {
        name: 'Legislative Associate',
        subtitle: 'On-demand, AI-powered interactive support',
        description:
            'Your AI-powered team member that supercharges your legislative research, analysis, and communication.',
        href: null,
        icon: ChatBubbleLeftRightIcon,
        imageUrl: '/demo-screen.png',
    },
    {
        name: 'Legislative Moneyball',
        subtitle: 'Deep insights & foresights',
        description:
            'Statistical optimization of your legislative strategy for allocation of time and money to drive outcomes.',
        href: null,
        icon: SparklesIcon,
        imageUrl: '/demo-screen.png',
    },
]

export const billboardTiers = [
    {
        name: 'Starter',
        id: 'tier-starter',
        buttonLabel: 'Request your Bill Board',
        price: { free: true },
        description: 'Everything necessary to get started:',
        features: [
            '1 editor',
            '1 Bill Board',
            '10 Bill Cards per Bill Board',
            'Embed on your website',
            'Bill FAQ w/ Billie AI',
        ],
    },
    {
        name: 'Advocate',
        id: 'tier-advocate',
        buttonLabel: 'Buy Advocate',
        price: { monthly: '$50', annually: '$40' },
        description: 'Everything in Starter, and:',
        features: [
            '2 editors',
            '10 Bill Boards',
            '40 Bill Cards per Bill Board',
            'Bill Board branding',
            'Bill Card customization',
            'Social media assets',
            'Custom Bill Card CTA',
            'Call my rep tools for members',
        ],
    },
    {
        name: 'Advocate + AI',
        id: 'tier-advocate-ai',
        buttonLabel: 'Buy Advocate + AI',
        price: { monthly: '$120', annually: '$100' },
        description: 'Everything in Advocate, and:.',
        features: [
            '5 editors',
            '100 Bill Boards',
            '100 Bill Cards per Bill Board',
            'Custom prompts for Billie AI Q&A',
            'Mobilization video generator',
            'Real-time alerts',
            'AI mobilization call scripts',
            'Open-ended Billie AI Q&A',
        ],
    },
]
